<template>


  <v-row no-gutters>
      <v-col>
        <div id="calculate-collection">
  
          <v-card 
              tile
              :flat="$vuetify.breakpoint.mdAndDown" 
              :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'">
            <v-toolbar 
            v-if="!$vuetify.breakpoint.smAndDown"
            :color="$vuetify.breakpoint.mdAndDown ? 'transparent' : 'transparent'"
            flat
            :height="$vuetify.breakpoint.smAndDown ? 57 : 87"   >
  
            <v-toolbar-title class="text-h6 font-weight-medium">
                <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
                  {{ icons.mdiCalculatorVariant }}
                </v-icon>
               Tahsilat Hesapla
            </v-toolbar-title>

            <v-spacer></v-spacer>   

            </v-toolbar>
            <v-divider></v-divider>
           
          
                <!-- table  -->
            <v-data-table    
                v-model="selectedRows"
                :headers="tableColumns"
                :items="collectionData"      
                :items-per-page="-1"
                :height="dataTableHeight"
                dense
                :loading="loading"
                hide-default-footer
              >
             

                <template #[`item.due_date`]="{ item }">                
                  <v-row >
                    <v-col cols="16">
                      <v-menu                   
                      v-model="item.dateMenu"
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"   
                    >
                         <template v-slot:activator="{ on, attrs }">
                          <v-text-field 
                            v-model="item.due_date"                                        
                            persistent-hint
                            :prepend-icon="icons.mdiCalendar"                     
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                         </template>
              
                        <v-date-picker
                          v-model="date"
                          no-title
                          color="primary"
                          @input="item.due_date = parseDate(computedDateFormatted)"
                          
                        ></v-date-picker>   
                                        
                    </v-menu>                  
                    </v-col>                 
                  </v-row>
                </template>  
                <template #[`item.amount`]="{ item }">    
                  <VCurrencyField v-model="item.amount" 
                  :options="{ currency: 'TRY'}"                   
                  :ilkDeger=ilkDeger 
                  @change="onChange1" 
                   />                  
                </template>
            </v-data-table>
            <v-divider></v-divider>
            <v-card-actions >    
                <v-btn   
                @click="addData"
                :loading="loading"
                fab
                dark
                color="error"
                rounded="rounded-l-xl" 
                size="x-large" 
                block>HESAPLA
                <v-icon dark>
                  {{ icons.mdiCalculator }}
                </v-icon>
              </v-btn>
            
             
            </v-card-actions>
            <v-divider></v-divider>

            <v-row v-if="TutarKontrol===true" >
                <v-col>
                  <v-data-table                
                    :headers="headers"
                    :items="desserts"
                    :items-per-page="-1"
                    hide-default-footer
                    hide-default-header
                 
                  >
                    <template #[`item.name`]="{ item }">
                      <span
                        class="font-weight-bold"                    >
                        {{ item.name }}
                      </span>                    
                    </template>
                    <template #[`item.tutar`]="{ item }">
                      <v-chip >
                        {{ item.tutar | currency }}                           
                      </v-chip>    

                     
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>             
          </v-card>
        </div>
      </v-col>
  </v-row>
  </template>
    <script src="https://code.jquery.com/jquery-3.7.0.js"></script>
    <script>
    import moment from 'moment';
    import VSnackbars from 'v-snackbars'
    import expenseStoreModule from '@/views/expense/expenseStoreModule'   
    import VCurrencyField from '@/views/expense/currency/VCurrencyField'
    
    import { currency } from '@utils'
    import store from '@/store'
    import {
            mdiCalendar,
            mdiCalculatorVariant,
            mdiClose,
            mdiCalculator
  } from '@mdi/js'
    import { onMounted,onUnmounted, ref ,watch,computed} from '@vue/composition-api'
    import Vue from 'vue'
    export default {
      components: {
      VSnackbars,  
      VCurrencyField
  },
    setup() {
      const EXPENSE_APP_STORE_MODULE_NAME = 'app-expense'
      const selectedRows = ref([])
      const totalEventListTable = ref(0)
      const loading = ref(false)
      const TutarKontrol = ref(0)
      const date = ref(new Date().toISOString().substr(0, 10))//
      const menu1 = ref(false)
      const snackbars = ref([])
      const price =ref(0)
      const rowIndex =ref(0) 
      const desserts = ref([])      
      const ilkDeger = ref(true) 
      //#region DATE FORMAT
      const formatDate = dates => {
        if (!dates) return null
        const [year, month, day] = dates.split('-')
        return `${day}-${month}-${year}`
      }
      let dateFormatted = formatDate(new Date().toISOString().substr(0, 10))
  
      const parseDate = dates => {
        if (!dates) return null
        const [day,month, year] = dates.split('-')
        return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`
      }
  
      const computedDateFormatted = computed(() => formatDate(date.value)) 
  
    /*   watch(date, () => {
        let dateFormatted = formatDate(date.value)
      })   */
  
      //#endregion
     
  
     // Register module
     if (!store.hasModule(EXPENSE_APP_STORE_MODULE_NAME)) {
        store.registerModule(EXPENSE_APP_STORE_MODULE_NAME, expenseStoreModule)
     }
     // UnRegister on leave
     onUnmounted(() => {
        if (store.hasModule(EXPENSE_APP_STORE_MODULE_NAME)) 
        store.unregisterModule(EXPENSE_APP_STORE_MODULE_NAME)
      })
  
    
      const tableColumns = [  
        {
          text: '',
          value: 'document_id',
          width: '50px'
        },   
        {
          text: 'VADE TARİHİ',
          value: 'due_date',
          width: '180px'
        },      
        {
          text: 'TUTAR',
          value: 'amount',
          width: '150px',
        }      
      ]
      
      return {        
        icons: {mdiCalendar,
            mdiCalculatorVariant,mdiClose,mdiCalculator},
        tableColumns,
        selectedRows ,
        loading,
        TutarKontrol,
        date,
        parseDate,
        formatDate,
        menu1,
        computedDateFormatted,
        dateFormatted,
        snackbars,
        desserts,  
        ilkDeger 
      }    
      },

      
      data: () => ({   
        manuelTutar: null,    
        headers: [
        {
          text: 'Ad',
          align: 'right',
          sortable: false,
          value: 'name',
        },

        {
          text: 'Değer',
          sortable: false,
          align: 'right',
          value: 'tutar',
        },
      ],

        collectionData: [
                        {document_id: 'Çek 1',due_date: moment(String(new Date().toISOString().substr(0, 10))).format('DD-MM-YYYY') , amount: 0 },
                        {document_id: 'Çek 2',due_date: '', amount: 0 },
                        {document_id: 'Çek 3',due_date: '' , amount: 0 },
                        {document_id: 'Çek 4',due_date: '', amount: 0 },
                        {document_id: 'Çek 5',due_date: '', amount: 0 },
                        {document_id: 'Çek 6',due_date: '', amount: 0 },
                        {document_id: 'Çek 7',due_date: '', amount: 0 },
                        {document_id: 'Çek 8',due_date: '', amount: 0 },
                        {document_id: 'Çek 9',due_date: '', amount: 0 },
                        {document_id: 'Çek 10',due_date: '', amount: 0 },

                    ],

         collectionData2: [ {degerTarih:'',degerTutar:'' }],

         desserts: [
              {
                name: 'Evrak Toplamı',
                tutar: 0,
              },
              {
                name: 'Tahsilat%',
                tutar: 0,
              },
              ],

        loading: false ,  
         
      }),
    
  
    methods:{        


    onChange1(value) {     
        this.TutarKontrol = false
      },
     
  
    
      addData(){
        debugger
      this.collectionData2=['']
      this.loading = true
      let tutarsiz = 0
       for (let index = 0; index < this.collectionData.length; index++) {               
            const element = this.collectionData[index];
            const tutar = element.amount 
  
            if(tutar===0){
                 tutarsiz+=1
            }
            //element.amount 
            const newPrice=  new Intl.NumberFormat('tr-TR', {
              style: 'decimal',
              minimumFractionDigits: 2,
              }).format(tutar)
              this.collectionData2.push({degerTarih: element.due_date ,degerTutar: newPrice})       
              
           
        }        
        debugger
        this.ilkDeger=false
        if(tutarsiz===10){
          this.loading = false
        Vue.swal({
                  title: 'Hata',
                  html: "Tutar Alanları Boş Olamaz!!",
                  icon: 'error',
                  background: '#FF4C51',
                  timerProgressBar: true,
                  showConfirmButton: false,
                })
            return
          }
        
        store
            .dispatch('postMethod', {
              method: 'calcTahsilatForm',  
              'tarih[1]' : this.collectionData2[1].degerTarih,
              'tutar[1]' : this.collectionData2[1].degerTutar,
              'tarih[2]' : this.collectionData2[2].degerTarih,
              'tutar[2]' : this.collectionData2[2].degerTutar,
              'tarih[3]' : this.collectionData2[3].degerTarih,
              'tutar[3]' : this.collectionData2[3].degerTutar,
              'tarih[4]' : this.collectionData2[4].degerTarih,
              'tutar[4]' : this.collectionData2[4].degerTutar,
              'tarih[5]' : this.collectionData2[5].degerTarih,
              'tutar[5]' : this.collectionData2[5].degerTutar,
              'tarih[6]' : this.collectionData2[6].degerTarih,
              'tutar[6]' : this.collectionData2[6].degerTutar,
              'tarih[7]' : this.collectionData2[7].degerTarih,
              'tutar[7]' : this.collectionData2[7].degerTutar,
              'tarih[8]' : this.collectionData2[8].degerTarih,
              'tutar[8]' : this.collectionData2[8].degerTutar,
              'tarih[9]' : this.collectionData2[9].degerTarih,
              'tutar[9]' : this.collectionData2[9].degerTutar,
              'tarih[10]': this.collectionData2[10].degerTarih,
              'tutar[10]': this.collectionData2[10].degerTutar,
             
            })
            .then(response => {
              console.log(response)
              debugger
              if (response.error == 1) {
                Vue.swal({
                  title: 'Hata',
                  html: response.msg,
                  icon: 'error',
                  background: '#FF4C51',
                  timerProgressBar: true,
                  showConfirmButton: false,
                })
              } else {
                debugger
              this.TutarKontrol=true
           
              this.desserts=[
              {
                name: 'Evrak Toplamı',
                tutar: response.totEvrakToplami,
              },
              {
                name: 'Tahsilat%',
                tutar: response.totTahsilatOrani,
              },
              {
                name: 'Evrak Ortalama Vadesi',
                tutar: response.evrakOrtVadesi,
              },
              {
                name: 'Sistem Vadesi',
                tutar: response.sistemVadesi,
              },
              {
                name: 'Toplam',
                tutar: response.genExtraTotal,
              },             
              ]
              this.loading = false
              }
             
            })     
          },
      
      priceFormat: function(item){
        const index = this.expenseData.indexOf(item);    
        debugger
        let nok_say=0
        let vir_say=0
        for(let karakter of this.expenseData[index].expense_amount.toString()) {
          if(karakter===".")
             nok_say+=1;
           if(karakter===",")
             vir_say+=1;
        }
  
        let number = (this.expenseData[index].expense_amount) //1250
        if(nok_say >1 || vir_say>0 ){
          number = (this.expenseData[index].expense_amount).replaceAll(".","") //1.254.485,54
        }
        else if ( nok_say === 1 || vir_say===0){
          number = (this.expenseData[index].expense_amount).replace(".",",") //1250.55 *binlik aracı nokta olarak girdiğinde
        }
        else if ( nok_say >= 1 || vir_say > 0){ 
          number = (this.expenseData[index].expense_amount).replace(".","") //1.455.250,55 *binlik aracı nokta olarak girdiğinde
        }
  
  /*       var formatter = new Intl.NumberFormat('tr-TR', {
          style: 'decimal',
          minimumFractionDigits: 2,
        }); */
  
        let formatCurrency= currency.format(number.replace(/[^\d\.]/g, ".")) // virgül yerine nokta olmalı!
        if (formatCurrency==='NaN') {
          item.expense_amount=""
        }
        else{
          item.expense_amount= formatCurrency
        }     
        
      },
  },
  
  }
    </script>
    
    <style>
    .v-application .primary--text {
    color: var(--v-secondary-base) !important;
    caret-color: var(--v-secondary-base) !important;
  }
  </style>
    |