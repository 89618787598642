var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{attrs:{"id":"calculate-collection"}},[_c('v-card',{class:_vm.$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg',attrs:{"tile":"","flat":_vm.$vuetify.breakpoint.mdAndDown}},[(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-toolbar',{attrs:{"color":_vm.$vuetify.breakpoint.mdAndDown ? 'transparent' : 'transparent',"flat":"","height":_vm.$vuetify.breakpoint.smAndDown ? 57 : 87}},[_c('v-toolbar-title',{staticClass:"text-h6 font-weight-medium"},[_c('v-icon',{attrs:{"large":!_vm.$vuetify.breakpoint.smAndDown,"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiCalculatorVariant)+" ")]),_vm._v(" Tahsilat Hesapla ")],1),_c('v-spacer')],1):_vm._e(),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.collectionData,"items-per-page":-1,"height":_vm.dataTableHeight,"dense":"","loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.due_date",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"16"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"persistent-hint":"","prepend-icon":_vm.icons.mdiCalendar},model:{value:(item.due_date),callback:function ($$v) {_vm.$set(item, "due_date", $$v)},expression:"item.due_date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(item.dateMenu),callback:function ($$v) {_vm.$set(item, "dateMenu", $$v)},expression:"item.dateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","color":"primary"},on:{"input":function($event){item.due_date = _vm.parseDate(_vm.computedDateFormatted)}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)],1)]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('VCurrencyField',{attrs:{"options":{ currency: 'TRY'},"ilkDeger":_vm.ilkDeger},on:{"change":_vm.onChange1},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}})]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}}),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"loading":_vm.loading,"fab":"","dark":"","color":"error","rounded":"rounded-l-xl","size":"x-large","block":""},on:{"click":_vm.addData}},[_vm._v("HESAPLA "),_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiCalculator)+" ")])],1)],1),_c('v-divider'),(_vm.TutarKontrol===true)?_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.desserts,"items-per-page":-1,"hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.tutar",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(" "+_vm._s(_vm._f("currency")(item.tutar))+" ")])]}}],null,true)})],1)],1):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }